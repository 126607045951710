/* 
 * Mindaugas Dukauskas mindaugas@svetaine.lt
 * scrollingEvents 0.0.1
 */
(function ($) {
    var $window = $(window);
    var $document = $(document);
    var $events = [];
    var scroll_timeout = null;
    var current_scroll_top = 0;
    var current_scroll_bottom = 0;
    var window_height = 0;
    $.fn.addScrollingEvent = function(event){
        event.offset = $('#'+event.elem).offset().top;
        event.height = $('#'+event.elem).height();
        event.halfHeight = event.height / 2;
        event.bottomOffset = event.offset + event.height;
        event.finished = false;
        event.fn = window[event.fn];
        $events.push(event);
    };

    $.fn.recalculateEvents = function(){
        for(var i=0; i < $events.length; i++){
            $events[i].offset = $('#'+$events[i].elem).offset().top;
            $events[i].height = $('#'+$events[i].elem).height();
            $events[i].halfHeight = $events[i].height / 2;
            $events[i].bottomOffset = $events[i].offset + $events[i].height;
            $events[i].finished = false;
        }
    };
    
    $.fn.scrollingEvents = function (options) {
        var settings = $.extend({}, {}, options);
        current_scroll_top = $window.scrollTop();
        window_height = $window.height();
        current_scroll_bottom = current_scroll_top + window_height;
        checkEvents();
        return this.each(function(){
            if (window.addEventListener) {
                window.addEventListener('scroll', scroller, false);
                window.addEventListener('resize', resizer, false);
            } else if (window.attachEvent) {
                window.attachEvent('onscroll', scroller);
                window.attachEvent('onresize', resizer);
            }
        });
        
        function scroller(){
            current_scroll_top = $window.scrollTop();
            current_scroll_bottom = current_scroll_top + window_height;
            checkEvents();
        }
        
        function resizer(){
            clearTimeout(scroll_timeout);
            scroll_timeout = setTimeout(function(){
                current_scroll_top = $window.scrollTop();
                window_height = $window.height();
                current_scroll_bottom = current_scroll_top + window_height;
                recalculateHeight();
            },200);
        }
        
        function recalculateHeight(){
            for(var i=0; i < $events.length; i++){
                if($events[i].finished === false){
                    $events[i].offset = $('#'+$events[i].elem).offset().top;
                    $events[i].height = $('#'+$events[i].elem).height();
                    $events[i].halfHeight = $events[i].height / 2;
                    $events[i].bottomOffset = $events[i].offset + $events[i].height;
                }
            }
        }

        function reset(){
            for(var i=0; i < $events.length; i++){
                $events[i].finished = false;
            }
        }
        
        function checkEvents(){
            for(var i=0; i < $events.length; i++){
                if(($events[i].offset >= current_scroll_top && $events[i].offset <= current_scroll_bottom) || ($events[i].bottomOffset >= current_scroll_top && $events[i].bottomOffset <= current_scroll_bottom)){
                    runEvent($events[i]);
                }
            }
        }
        
        function runEvent(event){
            if(event.finished === false){
                event.fn($('#'+event.elem));
                event.finished = true;
            }
        }
    };
})(jQuery);




/* 
 * Mindaugas Dukauskas mindaugas@svetaine.lt
 * Magic Products 0.0.1
 */
(function ($) {
        $.fn.magicProducts = function (options) {
            var settings = $.extend({}, {}, options);
            var elems = null;
            var main_holder = null;
            var elem_count = 0;
            var pages_count = 0;
            var elem_per_row = 0;
            var last_elem_per_row = 0;
            var column_width = 0;
            var win_width = 0;
            var holder_width = 0;
            var holder_tpl = '<span class="product_slide_out">'+
                             '   <span class="product_triangle"></span><div class="product_close_btn"></div>'+
                             '</span>';
            var video_tpl = '<iframe style="width:100%" src="" frameborder="0" allowfullscreen></iframe>';
            
            return this.each(function(){
                main_holder = $(this);
                changeClasses(main_holder);
                bindProductClicks(main_holder);
                changeClasses(main_holder);
                $(window).resize(function(){
                    changeClasses(main_holder);
                });
            });
            
            function bindProductClicks(elem){
                    elem.find('.products_holder>li').each(function(){
                        var product = $(this);
                        product.click(function(){
                            productClick(elem, $(this));
                        });
                    });           
            }
            
            function getLastRowElement(parent_index){
                var result = Math.ceil((parent_index + 1) / (elem_per_row));
                result = (result * elem_per_row) - 1;
                result = result > elem_count ? (elem_count - 1) : result;
                return result;
            }
            
            function productClick(elem, product){
                var parent = product.closest('.category_holder');
                var parent_index = parent.index('.category_holder');
                var content_height = product.find('.content').height() + 150;
                var content_elem = null;
                var id_append_after = getLastRowElement(parent_index);
                if( !product.hasClass('active')){
                    // SETS ACTIVE PRODUCT LI
                    elem.find('.products_holder>li').removeClass('active');
                    product.addClass('active');

                    if(elem.find('.product_slide_out').length > 0){
 
                        elem.find('.product_slide_out').slideUp(500,function(){
                            $(this).remove();
                            var video_height = 0;/*getVideoHeight(product);*/
                            content_elem = $(holder_tpl).height(0).append(product.find('.content').html()).insertAfter(elems[id_append_after]);
                            bindCloseBtn(content_elem);
                            initVideo(content_elem);
                            initGallery(content_elem);
                            content_elem.animate({height : (content_elem.find('.content_wrap').height() + 60 + video_height) + 'px'},500);
                            var triangle_left = (parent.offset().left - content_elem.offset().left) - 10 + (parent.width() / 2);
                            content_elem.find('.product_triangle').css({left:triangle_left+'px'});
                        });
                    }else{
                        var video_height = 0;/*getVideoHeight(product);*/
                        content_elem = $(holder_tpl).height(0).append(product.find('.content').html()).insertAfter(elems[id_append_after]);
                        bindCloseBtn(content_elem);
                        initVideo(content_elem);
                        initGallery(content_elem);
                        content_elem.animate({height : (content_elem.find('.content_wrap').height() + 60 + video_height) + 'px'},500);
                         var triangle_left = (content_elem.offset().left - parent.offset().left) - 10 + (parent.width() / 2);
                         content_elem.find('.product_triangle').css({left:triangle_left+'px'});
                    }
                }else{
                    elem.find('.product_slide_out').slideUp(500,function(){
                        product.removeClass('active');
                        $(this).remove();
                    });
                }
                last_elem_per_row = elem_per_row;
            }
            
            function initGallery(content_elem){
                if(content_elem.find("a[rel^='prettyPhoto']").length > 0){
                    content_elem.find("a[rel^='prettyPhoto']").vanillabox({animation: 'default',
                        closeButton: true,
                        keyboard: true,
                        loop: false,
                        adjustToWindow: 'both'
                    });
                }
            }
            
            function bindCloseBtn(content_elem){
                content_elem.find('.product_close_btn').click(function(){
                    content_elem.slideUp(500,function(){
                        main_holder.find('.products_holder>li').removeClass('active');
                        $(this).remove();
                    });
                });
            }
            
            function getVideoHeight(content_elem){
                if(content_elem.find('.video_holder').length > 0){
                    var right_elem = content_elem.find('.product_right_col').clone().appendTo('body');
                    var vid_width = right_elem.width() - 60;
                    var vid_height = vid_width * 0.5625;
                    right_elem.remove();
                    return vid_height;
                }else{
                    return 0;
                }   
            }
            
            function initVideo(content_elem){
                if(content_elem.find('.video_id').length > 0){
                    
                    var url = 'http://www.youtube.com/embed/'+content_elem.find('.video_id').val()+'?rel=0&hd=1';
                    var vid_width = content_elem.find('.video_holder').width();
                    var vid_height = vid_width * 0.5625;
                    var video_elem = $(video_tpl).attr('src',url).css({width:'100%', height : vid_height+'px'});
                    video_elem.appendTo(content_elem.find('.video_holder'));
                    return video_elem.height();
                }
                return 0;
            }
            
            function changeClasses(elem){
                win_width = window.innerWidth;
                holder_width = elem.find('.categories_holder_inline').width();
                
                if(win_width <= 1200 && win_width >= 992){
                    elem.removeClass('four_col');
                    elem.addClass('three_col');
                    elem.removeClass('two_col');
                    elem.removeClass('one_col');
                }else if(win_width >= 595 && win_width <= 991){
                    elem.removeClass('four_col');
                    elem.removeClass('three_col');
                    elem.addClass('two_col');
                    elem.removeClass('one_col');
                }else if(win_width <= 594){
                    elem.removeClass('four_col');
                    elem.removeClass('three_col');
                    elem.removeClass('two_col');
                    elem.addClass('one_col');
                }else{
                    elem.addClass('four_col');
                    elem.removeClass('three_col');
                    elem.removeClass('two_col');
                    elem.removeClass('one_col');
                }
                resize_products(elem);
            }
            
            function resize_products(elem){
                elem_per_row = 4; 
                if(elem.hasClass('three_col')){
                    elem_per_row = 3;
                }else if(elem.hasClass('two_col')){
                    elem_per_row = 2;
                }else if(elem.hasClass('one_col')){
                    elem_per_row = 1;
                }
                
                if(elem_per_row !== last_elem_per_row ){
                    elem.find('.product_slide_out').remove();
                    main_holder.find('.products_holder > li').removeClass('active');
                }
                
                elems = elem.find('.category_holder');
                elems.each(function(){
                    $(this).css({height:'auto'});
                });
                
                elem_count = elems.length;
                pages_count = Math.ceil(elem_count / elem_per_row);
                column_width =  Math.floor((holder_width - (14 * (elem_per_row - 1)))/ elem_per_row) ;
               
                for(i=0; i < pages_count; i++){
                    var height = 0;
                    var start_index = i * elem_per_row;
                    for(j = 0; j < elem_per_row; j++){
                        var index = start_index + j;
                        if($(elems[index]).length > 0){
                            var elem_height = $(elems[index]).height();
                            if(height < elem_height){
                                height = elem_height;
                            }
                        }
                    }

                    for(j = 0; j < elem_per_row; j++){
                        var index = start_index + j;
                        if($(elems[index]).length > 0){
                            $(elems[index]).height(height);
                            $(elems[index]).width(column_width - 40);
                        }
                    }
                    
                }
                setTopMargin(elems,pages_count,elem_per_row);
            }
            
            function setTopMargin(elems,pages_count,elem_per_row){
                 elems.find(".products_holder").css({"margin-top":'20px'});
                for(i=0; i < pages_count; i++){
                    var height = 0;
                    var start_index = i * elem_per_row;
                    for(j = 0; j < elem_per_row; j++){
                        var index = start_index + j;
                        if($(elems[index]).length > 0){
                            var elem_height = $(elems[index]).find('.products_holder').height();
                            if(height < elem_height){
                                height = elem_height;
                            }
                        }
                    }

                    for(j = 0; j < elem_per_row; j++){
                        var index = start_index + j;
                        if($(elems[index]).length > 0){
                            $(elems[index]).find('.products_holder').css({"margin-top" : 20 + (height - $(elems[index]).find('.products_holder').height())+"px"});
                        }
                    }
                }
            }
        };
    })(jQuery);


/* 
 * Mindaugas Dukauskas mindaugas@svetaine.lt
 * Magic Services 0.0.1
 */
(function ($) {
    $.fn.magicServices = function (options) {
        var settings = $.extend({}, {}, options);
        var elems = null;
        var main_holder = null;
        var elem_count = 0;
        var pages_count = 0;
        var elem_per_row = 0;
        var last_elem_per_row = 0;
        var column_width = 0;
        var win_width = 0;
        var holder_width = 0;
        var holder_tpl = '<span class="product_slide_out">'+
                         '   <span class="product_triangle"></span><div class="product_close_btn"></div>'+
                         '</span>';
        var video_tpl = '<iframe style="width:100%" src="" frameborder="0" allowfullscreen></iframe>';
        
        return this.each(function(){
            main_holder = $(this);
            changeClasses(main_holder);
            bindProductClicks(main_holder);
            changeClasses(main_holder);
            $(window).resize(function(){
                changeClasses(main_holder);
            });
        });
        
        function bindProductClicks(elem){
//            console.log(elem);
            elem.each(function(){
            $(this).find('.service_holder').each(function(i){
                var product = $(this);
//                console.log(product);
                product.click(function(){
                    productClick(elem, $(this), i);
                });
            });
            });
            
        }
        
        function getLastRowElement(parent_index){
            var row = Math.ceil((parent_index + 1) / (elem_per_row));
            last_elem_id = (row * elem_per_row);
            result = last_elem_id > elem_count ? (elem_count - 1) : (last_elem_id - 1);
            return result;
        }
        
        function productClick(elem, product, i){
            var parent = product.closest('.service_holder');            
//            var parent_index = parent.index('.service_holder');
            var parent_index = i;
            var content_height = product.find('.content').height() + 150;
            var content_elem = null;
            var id_append_after = getLastRowElement(parent_index);
//            var id_append_after = i;
//            console.log('appendinu po ' + id_append_after);
            if( !product.hasClass('active')){
                // SETS ACTIVE PRODUCT LI
                elem.find('.service_holder').removeClass('active');
                product.addClass('active');

                if(elem.find('.product_slide_out').length > 0){
                    elem.find('.product_slide_out').slideUp(500,function(){
                        $(this).remove();
                        var video_height = 0;/*getVideoHeight(product);*/
                        content_elem = $(holder_tpl).height(0).append(product.find('.content').html()).insertAfter(elems[id_append_after]);
                        bindCloseBtn(content_elem);
                        initVideo(content_elem);
                        initGallery(content_elem);
                        content_elem.animate({height : (content_elem.find('.content_wrap').height() + 60 + video_height) + 'px'},500);
                        var triangle_left = (parent.offset().left - content_elem.offset().left) - 10 + (parent.width() / 2);
                        content_elem.find('.product_triangle').css({left:triangle_left+'px'});
                        $('html, body').animate({
                            scrollTop: content_elem.offset().top-100
                        }, 500, function() {
                            $(window).trigger('resize.px.parallax');
                        });
                        
                    });
                }else{
                    var video_height = 0;/*getVideoHeight(product);*/
                    content_elem = $(holder_tpl).height(0).append(product.find('.content').html()).insertAfter(elems[id_append_after]);
                    bindCloseBtn(content_elem);
                    initVideo(content_elem);
                    initGallery(content_elem);
                    content_elem.animate({height : (content_elem.find('.content_wrap').height() + 60 + video_height) + 'px'},500);
                    
                     var triangle_left = (content_elem.offset().left - parent.offset().left) - 10 + (parent.width() / 2);
                     content_elem.find('.product_triangle').css({left:triangle_left+'px'});
                    $('html, body').animate({
                        scrollTop: content_elem.offset().top-100
                    }, 500, function() {
                        $(window).trigger('resize.px.parallax');
                    });
                }
            }else{
                elem.find('.product_slide_out').slideUp(500,function(){
                    product.removeClass('active');
                    $(this).remove();
                    $(window).trigger('resize.px.parallax');
                });
            }
            last_elem_per_row = elem_per_row;
        }
        
        function initGallery(content_elem){
            if(content_elem.find("a[rel^='prettyPhoto']").length > 0){
                content_elem.find("a[rel^='prettyPhoto']").vanillabox({animation: 'default',
                    closeButton: true,
                    keyboard: true,
                    loop: false,
                    adjustToWindow: 'both'
                });
            }
        }
        
        function bindCloseBtn(content_elem){
            content_elem.find('.product_close_btn').click(function(){
                var $scrollTo = ($(this).parent().parent());
                content_elem.slideUp(500,function(){
                    main_holder.find('.categories_holder .category_holder').removeClass('active');
                    $(this).remove();
                    $(window).trigger('resize.px.parallax');
                        $('html, body').animate({
                            scrollTop: $scrollTo.offset().top - 200
                        }, 500);
                });
            });
        }
        
        function getVideoHeight(content_elem){
            if(content_elem.find('.video_holder').length > 0){
                var right_elem = content_elem.find('.product_right_col').clone().appendTo('body');
                var vid_width = right_elem.width() - 60;
                var vid_height = vid_width * 0.5625;
                right_elem.remove();
                return vid_height;
            }else{
                return 0;
            }   
        }
        
        function initVideo(content_elem){
            if(content_elem.find('.video_id').length > 0){
                
                var url = 'http://www.youtube.com/embed/'+content_elem.find('.video_id').val()+'?rel=0&hd=1';
                var vid_width = content_elem.find('.video_holder').width();
                var vid_height = vid_width * 0.5625;
                var video_elem = $(video_tpl).attr('src',url).css({width:'100%', height : vid_height+'px'});
                video_elem.appendTo(content_elem.find('.video_holder'));
                return video_elem.height();
            }
            return 0;
        }
        
        function changeClasses(elem){
            win_width = window.innerWidth;
            holder_width = elem.find('.categories_holder_inline').width();
            
            if(win_width <= 1200 && win_width >= 992){
                elem.removeClass('four_col');
                elem.addClass('three_col');
                elem.removeClass('two_col');
                elem.removeClass('one_col');
            }else if(win_width >= 595 && win_width <= 991){
                elem.removeClass('four_col');
                elem.removeClass('three_col');
                elem.addClass('two_col');
                elem.removeClass('one_col');
            }else if(win_width <= 594){
                elem.removeClass('four_col');
                elem.removeClass('three_col');
                elem.removeClass('two_col');
                elem.addClass('one_col');
            }else{
                elem.addClass('four_col');
                elem.removeClass('three_col');
                elem.removeClass('two_col');
                elem.removeClass('one_col');
            }
            resize_products(elem);
        }
        
        function resize_products(elem){
            elem_per_row = 4; 
            if(elem.hasClass('three_col')){
                elem_per_row = 3;
            }else if(elem.hasClass('two_col')){
                elem_per_row = 2;
            }else if(elem.hasClass('one_col')){
                elem_per_row = 1;
            }
            
            if(elem_per_row !== last_elem_per_row ){
                elem.find('.product_slide_out').remove();
                main_holder.find('.service_holder').removeClass('active');
            }
            
            elems = elem.find('.category_holder');
            elems.each(function(){
                $(this).css({height:'auto'});
            });
            
            elem_count = elems.length;
            pages_count = Math.ceil(elem_count / elem_per_row);
            column_width =  Math.floor((holder_width - (14 * (elem_per_row - 1)))/ elem_per_row) ;
           
            for(i=0; i < pages_count; i++){
                var height = 0;
                var start_index = i * elem_per_row;
                for(j = 0; j < elem_per_row; j++){
                    var index = start_index + j;
                    if($(elems[index]).length > 0){
                        var elem_height = $(elems[index]).height();
                        if(height < elem_height){
                            height = elem_height;
                        }
                    }
                }

                for(j = 0; j < elem_per_row; j++){
                    var index = start_index + j;
                    if($(elems[index]).length > 0){
                        $(elems[index]).height(height);
                        $(elems[index]).width(column_width);
                    }
                }
                
            }
            setTopMargin(elems,pages_count,elem_per_row);
        }
        
        function setTopMargin(elems,pages_count,elem_per_row){
             elems.find(".products_holder").css({"margin-top":'20px'});
            for(i=0; i < pages_count; i++){
                var height = 0;
                var start_index = i * elem_per_row;
                for(j = 0; j < elem_per_row; j++){
                    var index = start_index + j;
                    if($(elems[index]).length > 0){
                        var elem_height = $(elems[index]).find('.products_holder').height();
                        if(height < elem_height){
                            height = elem_height;
                        }
                    }
                }

                for(j = 0; j < elem_per_row; j++){
                    var index = start_index + j;
                    if($(elems[index]).length > 0){
                        $(elems[index]).find('.products_holder').css({"margin-top" : 20 + (height - $(elems[index]).find('.products_holder').height())+"px"});
                    }
                }
            }
        }
    };
})(jQuery);

var can_animate = false;
/* MODULES ANIMATIONS */
function contacts_animation(elem){
    if(can_animate){
        elem.find('.contacts_left')
                .css({left:'-100%',visibility:'visible'})
                .delay(100)
                .animate({left:'0'},2000);
        elem.find('.contacts_right')
                .css({right:'-100%',visibility:'visible'})
                .show()
                .delay(100)
                .animate({right:'0'},2000);
        elem.find('.contacts_center')
                .css({top:'-100%',visibility:'visible'})
                .delay(100)
                .animate({top:'0'},2000);
    }
}

function clients_animation(elem){
    if(can_animate){
        elem.find('.client_item')
                .css({opacity:'0'})
                .delay(800)
                .animate({opacity:'1'},2000);
    }
}

function gauges_animation(elem){
    if(can_animate){
        elem.find('.gauge_logo_holder img')
                .css({width:'0px',height: '0px'})
                .delay(800)
                .animate({width:'65px',height:'65px'},2000,"easeOutBounce");

        elem.find('.gauge_result')
                .css({opacity:'0'})
                .delay(800)
                .animate({opacity:1},2000);

        elem.find('.gauge_title')
                .css({opacity:'0'})
                .delay(800).animate({opacity:1},2000);
    }
}

function mini_shields_animation(elem){
    if(can_animate){
        elem.find('.shield_item img')
                .delay(200)
                .animate({width:'196px'},2000);
        elem.find('.shield_item h5')
                .delay(200)
                .animate({opacity:1},2000);
        elem.find('.shield_item .text_block')
                .delay(200)
                .animate({opacity:1},2000);
    }
}

function article_animation(elem){
    if(can_animate){
        elem.find('.text_block').delay(800)
            .animate({opacity:1},2000);
    }
}

function wecontact_animation(elem){
    if(can_animate){
        elem.find('.we_contact_text')
                .delay(100)
                .animate({zoom:'1'},2000);
        elem.find('.big_phone')
                .show()
                .delay(100)
                .animate({zoom:'1'},2000);
    }
}


function galleries_animation(elem){
    if(can_animate){
        // GALERIES ANIMATION CODE IF NEEDED
    }
}

function achievements_animation(elem){
    if(can_animate){
        animateNumbers();
    }
}

